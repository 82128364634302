<template>
  <i>
    <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.75 5.25L5.25 18.75"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round" />
      <path
        d="M18.75 18.75L5.25 5.25"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round" />
    </svg>
  </i>
</template>
<style scoped lang="scss">
i {
  width: 32px;
  height: 32px;
  display: inline-block;
}
</style>
